
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
} from "vue";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { useRouter } from "vue-router";
import Api from "@/services/Api";
import useAlert from '@/composables/Alert'
import { useLoaderStore } from "@/store/LoaderStore";

interface Project {
  id: string | number | null;
  name: string | number | null;
  description: string | number | null;
  url: string | number | null;
  is_active: number | null;
  created_at: string | number | null;
  updated_at: string | number | null;
}

export default defineComponent({
  name: "ProjectsList",

  components: {
    Title,
    Button
  },

  setup() {
    const router = useRouter();

    const { showTimeAlert } = useAlert()

    const projectsArray: Ref<Project[]> = ref([]);
    const isLoading = useLoaderStore();
    const projectModal: Ref<any> = ref({})
    const idProject: Ref<any> = ref(null)

    async function getAllProjects() {
      isLoading.open()
      try {
        const { data } = await Api.get('projects');
        projectsArray.value = data;
      } catch (error) {
        console.log("error")
      } finally {
        isLoading.close()
      }
    }

    async function deletarProjeto(id: any) {
      const { data } = await Api.delete('deleteProjects', { id: id });
      if (data.error) return showTimeAlert('Tente novamente', "error")
      getAllProjects();
      return showTimeAlert('Projeto deletado com sucesso!', "success")
    }

    onMounted(async () => {
      getAllProjects()
    })

    return {
      projectsArray,
      history,
      router,
      projectModal,
      deletarProjeto,
      idProject,
      isLoading
    };
  },
});
